﻿import { Component, Input, OnInit, OnChanges, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';


import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ColumnConfig, TableConfig } from '../columnConfig';

@Component({
    selector: 'app-bms-tablerow',
    templateUrl: 'bms-tablerow.component.html'
})
export class BMSTableRowComponent implements OnInit, OnChanges {

    @Input()
    data: any;

    @Input()
    config: TableConfig;

    @Input()
    index: any;

    @Output()
    viewItem = new EventEmitter<any>();

    @Output()
    editItem = new EventEmitter<any>();

    @Output()
    selectItem = new EventEmitter<any>();

    @Output()
    bindItem = new EventEmitter<any>();

    @Output()
    deleteItem = new EventEmitter<any>();

    showView: boolean = false;
    showEdit: boolean = false;
    showDelete: boolean = false;

    ngOnInit() {

    }

    ngOnChanges(changes: any) {
        this.data.showView = this.config.showView;
        this.data.showEdit = this.config.showEdit;
        this.data.showDelete = this.config.showDelete;
        this.bindItem.emit({ Item: this.data });
    }

    getProperty(data: any, propertyName: string): any {
        return Reflect.get(data, propertyName);
    }


    goToDetail(event: any, data: any) {
        this.viewItem.emit({ Event: event, Item: data });

    }

    goToEdit(event: any, data: any) {
        this.editItem.emit({ Event: event, Item: data });
    }

    goToDelete(event: any, data: any) {
        this.deleteItem.emit({ Event: event, Item: data });
    }

    onSelect(event: any, data: any) {

        let checked: boolean;
        if (event.target) {
            checked = event.target.checked;
        }
        else if (event.srcElement) {
            checked = event.srcElement.checked;
        }
        this.selectItem.emit({ Event: event, Checked: checked, Item: data });
    }
}
