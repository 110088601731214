import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-database',
  templateUrl: './resume-database.component.html',
  styleUrls: ['./resume-database.component.css']
})
export class ResumeDatabaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
