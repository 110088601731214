import { Component, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
  selector: 'app-navigation',
  templateUrl: 'app.navigation.component.html'
})

export class AppNavigationComponent implements OnInit {
  @Output()
  menuClick = new EventEmitter<any>();
  menuVals = [];
  constructor() { }

  navigation(event: any) {    
    this.menuClick.emit(event);    
  }

  ngOnInit() {
    this.menuVals = [
      { "Id": 1, "Title": "Dashboard", "Url": "dashboard", "isOpen": null, "Items": [], "Icon": "<div class=\"dashboardicon\" />" },
      { "Id": 2, "Title": "Employee", "Url": "employee", "isOpen": null, "Items": [], "class":"hrms", "Icon": "<div class=\"employeeicon\" />" },
      { "Id": 3, "Title": "Recruitment", "Url": "recruitment", "isOpen": null, "Items": [], "Icon": "<div class=\"recruitmenticon\" />" },
      { "Id": 4, "Title": "Leave", "Url": "leave", "isOpen": null, "Items": [], "Icon": "<div class=\"leaveicon\" />" },
      { "Id": 5, "Title": "Attendance", "Url": "attendance", "isOpen": null, "Items": [], "Icon": "<div class=\"attendanceicon\" />" },
      { "Id": 6, "Title": "Roster", "Url": "roster", "isOpen": null, "Items": [], "Icon": "<div class=\"rostericon\" />" },
      { "Id": 7, "Title": "Resume Database", "Url": "resume-database", "isOpen": null, "Items": [], "Icon": "<div class=\"resumeicon\" />" },
      { "Id": 8, "Title": "Templates", "Url": "templates", "isOpen": null, "Items": [], "Icon": "<div class=\"templateicon\" />" },
      { "Id": 9, "Title": "Message", "Url": "message", "isOpen": null, "Items": [], "Icon": "<div class=\"messageicon\" />" },
      { "Id": 10, "Title": "Report", "Url": "report", "isOpen": null, "Items": [], "Icon": "<div class=\"reporticon\" />" },
    ]
  }
}
