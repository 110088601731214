import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TemplatesComponent } from './templates/templates.component';
import { ResumeDatabaseComponent } from './resume-database/resume-database.component';

const routes: Routes = [
  { path: 'login', loadChildren: './login/login.module#LoginModule', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent, //data: { breadcrumb: 'home' },
    children: [

  {path: 'dashboard',component:DashboardComponent},
  
  {path: 'resume-database',component:ResumeDatabaseComponent},

  {path: 'templates',component:TemplatesComponent},
    
      {path: 'home', redirectTo:"home"},
      {
        path: 'employee', loadChildren: './employee/employee.module#EmployeeModule', 
        data: { breadcrumb: 'Employee' }
      },
      {
        path: 'recruitment', loadChildren: './recruitment/recruitment.module#RecruitmentModule'
      },
      {
        path: 'leave', loadChildren: './leave/leave.module#LeaveModule'
      },
      {
        path: 'attendance', loadChildren: './attendance/attendance.module#AttendanceModule'
      },
      {
        path: 'roster', loadChildren: './roster/roster.module#RosterModule'
      },
      {
        path: 'message', loadChildren: './message/message.module#MessageModule'
      },
      {
        path: 'report', loadChildren: './report/report.module#ReportModule'
      },
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

