import { Component, Input, OnInit, OnChanges, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IMenu } from './bmsmenu.component';

@Component({
    selector: 'bms-menuitem',
    templateUrl: 'bmsmenu-item.component.html'
})
export class BMSMenuItem implements OnInit, OnChanges {
    @Input()
    item: IMenu;

    @Output()
    selectItem = new EventEmitter<any>();

    ngOnInit() {

    }
    ngOnChanges() {

    }
    childClick(event: any) {
        this.selectItem.emit(event);
    }
    selected(item: any) {
        item.isOpen = item.isOpen ? false : true;
        this.selectItem.emit(item);
    }
}
