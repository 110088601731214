import { Component, OnInit, HostListener, ViewChild, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppConfigService } from '../app.config.service';
import { IMenu } from '../ux/bmsmenu/bmsmenu.component';
import { MatSidenav, MatDrawer } from '@angular/material';
import { MaterialModuleControls } from '../material.module';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  screenWidth: number;
  title: String;
  imgPath: string = "";
  showBackNav: Boolean;
  selectedTab: String;


  public setTitleFromRoute() {
    // hide by back button By default
    const path = (this.router as any).location.path();

    if (path === '/home/employee') {
      this.title = 'Employee List';
      this.imgPath = "assets/images/patient_regist.png";
    } 
    else if (path === '/home/employee/background-verification') {
      this.title = 'Employee / Background Verification';
      this.imgPath = "assets/images/appointments-hover.png";
    }
     else if (path === '/home/employee/documents') {
      this.title = 'Employee / Documents';
      this.imgPath = "assets/images/visit-hover.png";
    } 
    else if (path === '/home/employee/enrollment-and-onboarding') {
      this.title = 'Employee / Enrollment-and-Onboarding';
      this.imgPath = "assets/images/opd-hover.png";
    }
      else if (path === '/home/employee/training') {
      this.title = 'Employee / Training';
      this.imgPath = "assets/images/opd-hover.png";
    }
      else if (path === '/home/helpdesk') {
      this.title = 'HelpDesk';
      this.imgPath = "assets/images/opd-hover.png";
    }

    // Patient subdivisions
      else if (path === '/home/recruitment') {
      this.title = 'Recruitment / Application List';
    }
      else if (path === '/home/recruitment/application') {
      this.title = 'Recruitment / Application List';
    }
      else if (path === '/home/recruitment/interview') {
      this.title = 'Recruitment / Interview';
    }
      else if (path === '/home/leave') {
      this.title = 'Leave / Leave Type List';
    }
      else if (path === '/home/leave/leave-type') {
      this.title = 'Leave / Leave Type List';
    }
      else if (path === '/home/leave/leave-mapping') {
      this.title = 'Leave / Leave Mapping';
    }
      else if (path === '/home/leave/holiday') {
      this.title = 'Leave / Holiday';
    }
      else if (path === '/home/leave/leave-setting') {
      this.title = 'Leave / Leave Setting';
    }
      else if (path === '/home/attendance') {
      this.title = 'Attendance / Calendar';
    }
      else if (path === '/home/attendance/calendar') {
      this.title = 'Attendance / Calendar';
    }
      else if (path === '/home/attendance/in-and-out-status') {
      this.title = 'Attendance / In-and-Out-Status';
    }
      else if (path === '/home/roster') {
      this.title = 'Roster';
    }
      else if (path === '/home/message') {
      this.title = 'Message';
    }
      else if (path === '/home/report') {
      this.title = 'Report';
    }
    

  }

  constructor(private router: Router) {
    // track login active status on route changed
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setTitleFromRoute();
      }
    });

    // set screenWidth on page load
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
     this.screenWidth = window.innerWidth;
    };
  }
} 
