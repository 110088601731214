import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { OrderModule } from 'ngx-order-pipe';
import { FormsModule} from '@angular/forms';
import { MaterialModuleControls } from '../../material.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BMSTableComponent } from './bmstable.component';
import { BMSTableRowComponent } from './bmstablerow.component';
import { BMSTableCellComponent } from './bmstablecell.component';


@NgModule({
    imports: [
        CommonModule,
        OrderModule,
        NgbDropdownModule,
        FormsModule,
        MaterialModuleControls
    ],
    declarations: [ BMSTableComponent, BMSTableRowComponent, BMSTableCellComponent],
    providers: [],
    exports: [CommonModule, BMSTableComponent, BMSTableRowComponent, BMSTableCellComponent]
})
export class BMSTableModule {

}

