import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { MaterialModuleControls } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreadcrumbsModule } from 'ng6-breadcrumbs';
import { DxSchedulerModule } from 'devextreme-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BMSUxModule } from './ux/bms.ux.module';
import { BMSTableModule } from './ux/bmstable/bms-table.module';
import 'hammerjs';
import { HomeComponent } from './home/home.component';
import { AppNavigationComponent } from './core/app.navigation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TemplatesComponent } from './templates/templates.component';
import { ResumeDatabaseComponent } from './resume-database/resume-database.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppNavigationComponent,
    DashboardComponent,
    TemplatesComponent,
    ResumeDatabaseComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    MaterialModuleControls,
    ReactiveFormsModule,
    DxSchedulerModule,
    BreadcrumbsModule,
    NgbModule,
    BMSUxModule,
    BMSTableModule,    
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


